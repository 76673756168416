<template>
  <!-- 新增修改页面 -->
  <div>
    <!-- form 表单 -->
    <div>
      <el-form :model="data" label-width="100px" v-loading="loading">
        <el-form-item label="广告描述">
          <el-input
            placeholder="广告描述"
            size="mini"
            style="width: 500px"
            v-model="data.describes"
          ></el-input>
        </el-form-item>
        <el-form-item label="显示分类">
          <el-select v-model="data.typeId" size="mini" placeholder="请选择分类">
            <el-option
              v-for="(item, index) in selector"
              :key="index"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
            <!-- <el-option label="PC首页" value="1"></el-option>
            <el-option label="手机设备" value="2"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            placeholder="请输入序号"
            size="mini"
            style="width: 400px"
            v-model.number="data.sort"
            onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          ></el-input>
        </el-form-item>
        <el-form-item label="背景色">
          <el-color-picker v-model="data.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="广告图">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="mainPicUpload"
          >
            <img v-if="data.image" :src="data.image" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- <el-form-item label="跳转类型">
          <el-radio v-model="data.radio" label="1">内部浏览器</el-radio>
          <el-radio v-model="data.radio" label="2">外部浏览器</el-radio>
          <el-radio v-model="data.radio" label="3">内部页面编号</el-radio>
        </el-form-item> -->
        <el-form-item label="跳转链接">
          <el-input
            v-model="data.url"
            size="mini"
            placeholder="内部跳转编码需开发提供，跳转浏览器填写为URL地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="" @click="getBack">返回</el-button>
          <el-button type="primary" @click="save">更新</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: {
        typeId: "",
        image: "",
        sort: "",
        url: "",
        describes: "",
        color: "",
      },
      imageUrl: "",
      selector: "",
    };
  },
  created() {
    // 获取下拉框
    this.axios.get("/admin/consultType/findAll", {}).then((res) => {
      this.selector = res.data.data.records;
    });
    this.data = this.$route.query.data;
  },
  methods: {
    //   列表详情
    // getBack2() {
    //   let newid = this.$route.query.id;
    //   console.log(newid, "++++++++");
    //   this.axios
    //     .get("/admin/indexImage/findAll", {
    //       id: newid,
    //     })
    //     .then((res) => {
    //       this.data = res.data.data[0];
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    // 保存
    save() {
      this.axios
        .put("/admin/indexImage/updateIndexImage", this.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.mesg,
              type: "success",
            });
            this.getBack();
          } else {
            this.$message({
              message: res.mesg,
              type: "error",
            });
          }
        });
    },

    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    // 上传图片
    mainPicUpload(options) {
      this.loading = true;
      function dataUrl(data) {
        let arr = data.split("&");
        let list = {};
        arr.forEach((item) => {
          let itm = item.split("=");
          list[itm[0]] = decodeURIComponent(itm[1]);
        });
        return list;
      }
      var { file } = options;
      let data;
      this.axios.get("/getOSSKey", {}).then((res) => {
        data = res.data.data;
        var ossData = new FormData();
        ossData.append("name", file.name);
        let filename = `${Date.parse(new Date())}` + file.name; //图片名称
        let keyValue = data.dir + filename;
        //请求数据
        ossData.append("key", keyValue);
        ossData.append("callback", data.callback);
        ossData.append("policy", data.policy);
        ossData.append("OSSAccessKeyId", data.accessid);
        ossData.append("success_action_status", 200);
        ossData.append("signature", data.signature);
        ossData.append("file", file);
        this.axios.post(data.host, ossData).then((res) => {
          this.imageUrl = dataUrl(res.data.Status);
          this.imageUrl = this.imageUrl.filename;
          this.data.image = this.imageUrl;
          this.loading = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 上传图片
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border-radius: 10px;
  border: 1px dashed #8c939d;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 10px;
  border: 1px dashed #8c939d;
}
</style>
